
.home-container {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    overflow: none;
    
}

.call-to-action {
    text-decoration: none;
    color: darkblue;
    background-color: white;
    padding: 15px 20px;
    border-radius: 5px;
    font-weight: 600;
    transition: all 0.1s ease-in-out;
    box-shadow: 0px 0px 30px rgba(100, 100, 100, .4);
}

li > a {
    text-decoration: none;
    color: black;
}

.call-to-action:hover {
    box-shadow: 0px 0px 30px rgba(100, 100, 100, .75);
}

section {
    position: relative;
}

.section-content {
    width: 1000px;
    margin: auto;
}

section > .deco {
    z-index: -10;
}

section > .tilted-background-what {
    z-index: -11;
}

section > .section-content {
    z-index: 0;
}



.home-content {
    width: 1000px;  
    height: 100%;
}

.tilted-background-navbar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: rgb(255,215,247);
    background: linear-gradient(276deg, rgba(255,215,247,1) 0%, rgba(254,255,218,1) 100%);
    height: 400px;
    width: 100%;
    transform: rotate(-4deg) scale(1.4);
    box-shadow: 0px 0px 20px rgba(100, 100, 100, .1);
}

/* Navbar */

.navbar > section-content {
    margin-bottom: 20px;
}

.navbar-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
    
}

.navbar-menu > ul {
    display: flex;
    text-decoration: none;
    list-style: none;
    justify-content: center;
}

.navbar-menu > ul > li {
    padding: 20px;
}

.navbar-menu > ul > li {
    padding: 20px;
}

.navbar-menu > ul > li:hover {
    font-weight: bold;
    cursor: pointer;    
}

.navbar-brand {
    font-weight: 500;
    font-size: 2em;
}

.navbar-brand > span {
    font-family: 'Satisfy-Regular';
    font-style: 'italic';
    font-weight: 900;
}

/* sections */

.section-container {

}


/* accueil */



/* pq */

.header-catch {
    width: 65%;
}

.catch {
    font-weight: 800;
    font-size: 2.5em;
    padding-bottom: 40px;
}

.subcatch {
    font-weight: 400;
    font-size: 1.25em;
    padding-bottom: 60px;
}

.pourquoi {
    margin-top: 40px;
}

.pourquoi > .section-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-picture-container {
    position: relative;
}

.profile-picture-container > .pdp {
    height: 250px;
    border-radius: 50%;
    box-shadow: 0px 0px 20px rgba(50, 50, 50, 0.25);
}

.profile-picture-container > .cmoi {
    position: absolute;
    height: 75px;
    top: -50px;
}

/* quoi */

.services > .section-content {
    margin-top: 175px;
}

.tilted-background-what {
    position: absolute;
    left: 0;
    top: 200px;
    width: 100%;
    background: rgb(215,255,242);
    background: linear-gradient(-140deg, rgba(215,255,242,1) 0%, rgba(218,218,255,1) 100%);
    height: 500px;
    width: 100%;
    transform: rotate(4deg) scale(1.4);
    box-shadow: 0px 0px 20px rgba(100, 100, 100, .1);
}

.services-lines {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    transform: rotate(2deg);
    text-align: center;
}

.service-bloc {
    background-color: rgb(252, 252, 252);
    width: 25%;
    height: 200px;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.service-bloc:hover {
    background-color: rgb(255, 255, 255);
}

.service-title {
    font-size: 1.5em;
    font-weight: bolder;
}

.service-description {
    font-size: 0.75em;
}

.service-image > img {
    height: 75px;
}

.service-bloc.a, .service-bloc.c, .service-bloc.e {
    transform: rotate(1deg);
    transition: all 0.25s ease-in-out;
}

.service-bloc.a:hover, .service-bloc.c:hover, .service-bloc.e:hover {
    transform: rotate(-2deg) scale(1.1);
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1)
}

.service-bloc.b, .service-bloc.d, .service-bloc.f {
    transform: rotate(-1deg);
    transition: all 0.1s ease-in-out;
}

.service-bloc.b:hover, .service-bloc.d:hover, .service-bloc.f:hover {
    transform: rotate(1deg) scale(1.1);
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1)
}

/* taro */

.tarifs-bis {
   margin-top: 140px !important;
}

.tilted-background-prices {
    position: absolute;
    left: 0;
    top: 150px;
    width: 100%;
    background: rgb(234,255,190);
    background: linear-gradient(140deg, rgba(234,255,190,1) 0%, rgba(218,255,248,1) 100%);
    height: 250px;
    width: 100%;
    transform: rotate(-4deg) scale(1.4);
    box-shadow: 0px 0px 20px rgba(100, 100, 100, .1);
}

.prices > .section-content {
    margin-top: 100px;
}

.prices-header {
    text-align: center;
    transform: rotate(-3deg);
    font-weight: 700;
    font-size: 3em;
}

.prices-sub-header {
    text-align: center;
    font-weight: 300;
    font-size: 2em;
    margin-top: 20px;
}

.prices-container {
    display:flex;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 40px;
}

.prices-container-flex {
    display:flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 40px;
    justify-content: center;
}

.prices-container-flex > .price-bloc {
    font-size: 0.75em;
    padding: 20px;
}
.prices-container-flex > .price-bloc  .price-recc {
    font-size: 1.05em;
}


.price-bloc {
    padding: 40px;
    background-color: white;
    margin: 0px 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1)
}

.price-bloc-header {
    text-align: center;
}

.price-name {
    font-size: 1.5em;
}

.price-value {
    font-size: 2em;
    font-weight: bolder;
}

.price-recc {
    font-size: 0.75em;
}

.price-services-container {
    text-align: left;
    margin-top: 40px;
    font-size: 0.85em;
}

.price-service {
    margin-top: 4px;
    margin-bottom: 2px;
}

.service-new {
    font-weight: bolder;
    color: #00D26A;
}

.service-no {
    font-weight: light;
    color: #CCC;
}

.pack-button {
    margin-top: 30px;
}

.pack-button > a {
    text-decoration: none;
    color: white;
    background-color: #479dff;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: 600;
    transition: all 0.1s ease-in-out;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)
}

.pack-button-flex {
    margin-top: 15px;
    text-align: left;
}

.pack-button-flex > a {
    color: #479dff;
    font-weight: 600;
    transition: all 0.1s ease-in-out;
    text-decoration: none;
}

.pack-button-flex > a:after {
    content: ' →'
}

.copyright {
    //text-align: center;
    margin: 25px 0px;
}

.copyright > a {
    color: inherit;
}

.footer {
    padding-top: 30px;
    margin-top: 100px;
    color: black;
    background-color: white;
}

.footer > .section-content {
    display: flex;
    justify-content: space-between;
}

.footer-address {
    text-align: right;
}

.tilted-background-footer {
    position: absolute;
    left: 0;
    top: 100px;
    width: 100%;
    background: rgb(215,255,242);
    background: linear-gradient(-140deg, rgba(215,255,242,1) 0%, rgba(218,218,255,1) 100%);
    height: 100px;
    width: 100%;
    box-shadow: 0px 0px 20px rgba(100, 100, 100, .1);
}

.footer-address b, .footer-contact b {
    color: black;
    background-color: #DDFFED;
    padding: 2px 6px;
    border-radius: 4px;
}

.footer-address > div:nth-child(1), .footer-contact > div:nth-child(1) {
    margin-bottom: 10px;
}

.footer-contact {
    display: flex;
    flex-direction: column;
}

.footer-contact > a {
    text-decoration: none;
    color: black;
}


.footer-contact > a:hover {
    font-weight: 600;
}

.hidden {
	display: none !important;
}


@media (max-width: 1000px) {
    *:not(.deco) {
        transform: rotate(0)! important;
    }
    .section-content {
        width: 100%;
    }
    
    .navbar-menu {
        display: none;
    }

    .navbar-brand {
        text-align: center;
        width: 100%;
    }

    .pourquoi > .section-content {
        flex-direction: column-reverse;
    }

    .profile-picture-container {
        margin-bottom: 25px;
    }

    .subcatch {
        margin-top: 40px;
    }

    .services-lines {
        flex-direction: column;
        text-align: center;
        width: 100%;
        align-items: center;
    }

    .service-bloc {
        width: 75%;
        margin-bottom: 10px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1)
    }

    .service-description {
        font-size: 1em;
    }

    .prices-container-flex, .prices-container {
        flex-direction: column;
        align-items: center;
    }

    .prices-container-flex > div, .prices-container > div {
        margin-bottom: 20px;
        width: 75%;
    }

    .footer {
        align-items: center;
    }

    .footer > .section-content {
        width: 75%;
    }
}
