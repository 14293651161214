@font-face {
  font-family: "Satisfy-Regular";
  src: url("./fonts/Satisfy-Regular.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100vh;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body::-webkit-scrollbar{
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, 'Poppins',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}